import { ref } from 'vue'

export const useShowNews = () => {
  const RECORD_KEY = 'usercenter_newspannel_display_logic'
  const needExpose = () => {
    try {
      const navigationTiming =
        window.performance.getEntriesByType('navigation')[0]
      // 从缓存中加载页面不计入曝光
      return !['back_forward', 'reload'].includes(navigationTiming.type)
    } catch (e) {
      return true
    }
  }
  const getRecord = () => JSON.parse(localStorage.getItem(RECORD_KEY) || '{}')
  const setRecord = ({ type, time } = {}) => {
    let obj = getRecord()
    switch (type) {
      case 'addExp':
        if (!needExpose()) return
        obj = {
          ...obj,
          expNum: obj.expNum + 1
        }
        break
      case 'addCli':
        obj = {
          ...obj,
          cliNum: obj.cliNum + 1
        }
        break
      case 'reset':
        obj = {
          time,
          expNum: 1,
          cliNum: 0
        }
        break
    }
    localStorage.setItem(RECORD_KEY, JSON.stringify(obj))
  }
  const visible = ref(false)
  const handleShowNewsEntrance = (data, abtInfo) => {
    // 新策略返回 number（0,1,2 ...）代表疲劳值曝光次数
    const pageme_news_exposure = abtInfo.PageMeNews?.p?.pageme_news_exposure
    // 旧策略
    if (
      pageme_news_exposure == '' ||
      pageme_news_exposure == undefined ||
      pageme_news_exposure === 'old'
    ) {
      visible.value = data.length > 0
      return
    }
    // 新策略
    if (!data.length) {
      return (visible.value = false)
    }
    const { time, expNum, cliNum } = getRecord()
    const curTIme = data[0].startTime
    if (
      // 曝光n次，且该设备未点击过news栏
      time === curTIme &&
      expNum >= +pageme_news_exposure &&
      (cliNum || 0) === 0
    ) {
      visible.value = false
    } else {
      visible.value = true
      time !== curTIme
        ? setRecord({ type: 'reset', time: curTIme })
        : setRecord({ type: 'addExp' })
    }
  }

  return {
    handleShowNewsEntrance,
    visible
  }
}
