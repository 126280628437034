<template>
  <div>
    <s-dialog
      :visible="currentDialogShow"
      class="renew-dialognew"
      append-to-body
    >
      <!-- 弹窗主体 --> 
      <div class="renew-dialognew__bd">
        <!-- 关闭按钮 -->
        <SvgIcon
          color="#fff"
          class="renew-dialognew__close"
          name="sui_icon_close_34px" 
          size="34px"
          @click="closeDialog"
        />
        <!-- 顶部提示 -->
        <div
          class="renew-dialognew__topnotice"
        >
          <span class="renew-dialognew__notice_text">{{ configData?.mainTitle }} </span>
          <span class="renew-dialognew__notice_time">{{ configData?.mainTitleDay?.replace('{0}', Math.ceil(configData?.totalEndTime / 86400)) }}</span>
        </div>

        <!-- 弹窗中部 --> 
        <div class="renew-layout">
          <div
            class="renew-layout__hd"
            :style="{ backgroundImage: `url(${configData?.coinUrl})` }"
          >
          </div>
          <div
            class="renew-layout__light"
            :style="{ background: `center / cover no-repeat url(${configData?.coinBackgroundUrl})`, right: '-7px' }"
          >
          </div>

          <div
            v-if="configData?.rightType == 2"
            class="renew-layout__text"
          >
            <span class="renew-layout__text_spec renew-text__ellipsis">{{ configData?.subTitle }}</span>
            <br />
            <span class="renew-layout__text_wrapper">
              <span
              id="renew-notice-text"
              class="renew-layout__text_notice"
              >{{ configData?.renewContentTwo.replace('{0}', '') }}</span>
              <span
              id="renew-num-text"
              class="renew-layout__text_num renew-text__ellipsis"
              >{{ configData?.reqCurrencyPrice }}</span>
            </span>
          </div>

          <div
            v-if="configData?.rightType != 2 && configData?.subTitle"
            class="renew-layout__text"
            >
            <span class="renew-layout__text_subtitle renew-text__ellipsis">{{ configData?.subTitle }}</span>
          </div>

          <div
            :class="['renew-mainnew__bd', { 'renew-mainnew__bd_close': configData?.rightType == 2 }]"
          >
            <div
            v-if="configData?.rightType == 2"
            class="renew-mainnew__triangle"
            :style="{ background: `center / cover no-repeat url(${RENEW_IMG_V2.triangle})` }"
            ></div>
            <div
              class="renew-mainnew__background"
             :style="{ backgroundImage: `url(${configData?.rightBackgroundUrl})`, bottom: 0,
              right: 0 }"
            >
            </div>
            <div v-if="configData?.rightType != 2">
              <div class="renew-mainnew__title renew-text__ellipsis">
                {{ configData?.renewContentOne }}
              </div>

              <div class="renew-mainnew__content">
                <div 
                  class="renew-mainnew__save"
                >
                <span
                id="renew-save-text"
                class="renew-mainnew__save_text"
                >{{ configData?.renewContentTwo.replace('{0}', '') }} </span>
                <span
                id="renew-save-currency"
                class="renew-mainnew__save_currency renew-text__ellipsis"
                :title="configData?.reqCurrencyPrice"
                >{{ configData?.reqCurrencyPrice }}</span>
                </div>
                <div 
                  v-if="configData?.renewContentThree && configData?.roi && configData?.roi != 0"
                  class="renew-mainnew__roi" 
                >
                  <span>=</span>
                  <span :style="{ fontWeight: 860 }">{{ configData?.roi }} </span>
                  <span class="renew-mainnew__roi_num">X</span>
                  <span class="renew-mainnew__roi_text renew-text__ellipsis">{{ configData?.renewContentThree.replace('{0}', '') }}</span>
                </div>

                <div
                  class="renew-mainnew__divider"
                  :style="{ backgroundImage: `url(${RENEW_IMG_V2.dash})` }"
                >
                </div>

                <div
                  v-if="configData?.rightType != 2"
                  class="renew-mainnew__items"
                  >
                  <div
                    v-for="(item, index) in configData?.rightInfos || []"
                    :key="item?.icon"
                    :style="{ background: `center / cover no-repeat url(${item?.icon})` }"
                    :class="['renew-mainnew__item', { 'renew-mainnew__item_last': index == configData?.rightInfos?.length - 1 }]"
                  >
                  </div>
                </div>
              </div>
            </div>

            <div v-if="configData?.rightType == 2">
              <div class="renew-mainnew__items_col">
                  <div
                    v-for="(item, index) in configData?.rightInfos || []"
                    :key="item?.icon"
                    :class="['renew-mainnew__item_col']"
                  >
                  <div class="renew-mainnew__item_detail">
                    <span
                    class="renew-mainnew__item_ico"
                    :style="{ background: `center / cover no-repeat url(${item?.icon})` }"
                    ></span> 
                    <span
                      class="renew-mainnew__item_desc renew-text__ellipsis"
                      :title="item?.desc"
                      >{{ item?.desc }}</span>
                  </div>
                  <div class="renew-mainnew__item_wrapper">
                    <span
                    class="renew-mainnew__item_save renew-text__ellipsis"
                    :title="item?.saveText"
                    >{{ item?.saveText }}</span> 
                  </div>
                  <div
                    v-if="!((index == configData?.rightInfos?.length - 1) && index % 2 !== 0)"
                    class="renew-mainnew__item_dash" 
                    :style="{ backgroundImage: `url(${RENEW_IMG_V2.dashes})`, bottom: 0 }"
                  >
                  </div>
                  </div>
                </div>

                <div
                  v-if="configData?.rightText"
                  class="renew-mainnew__bt"
                >
                  <span>{{ configData?.rightText }}</span>
                </div>
            </div>
          </div>
        </div>

        <!-- 弹窗底部 -->
        <div class="renew-dialognew__ft">
          <div
            v-if="configData?.localPrice"
            :class="`renew-dialognew__promotion`"
            :style="{ backgroundImage: `url(${RENEW_IMG_V2.promotionBg})` }"
          >
            <span
            class="renew-dialognew__ico"
            :style="{ backgroundImage: `url(${RENEW_IMG_V2.flashIcon})` }"
            ></span>
            <span :class="['renew-dialognew__discount renew-text__ellipsis', { 'renew-dialognew__discount_over' : isOverThreeDays, 'renew-dialognew__discount_countdown': configData?.discountEndTime }]">{{ configData?.renewGuideDiscountText.replace('{0}', configData?.totalDiscountPrice) }}</span>
            <span
            :title="renewGuideText"
            :class="['renew-dialognew__timetext renew-text__ellipsis', { 'renew-dialognew__timetext_countdown': configData?.discountEndTime }]"
            >{{ renewGuideText }}</span>

            <span
              class="renew-dialognew__countdown"
              v-html="title"
            ></span>
          </div>
          <span
          :style="{ 'backgroundImage': `url(${configData?.buttonBackgroundUrl})` }"
            :class="['renew-dialognew__btn', { 'renew-dialognew__btn_coupon': !isOverThreeDays }]"
            @click="goToPrimeEvt"
          >
          <span :class="['renew-dialognew__btn_text renew-text__ellipsis', { 'renew-dialognew__btn_over': isOverThreeDays }]">{{ configData?.buttonText.replace('{0}', '') }} </span>
          <span :class="['renew-dialognew__btn_price', {'renew-dialognew__price_over' : isOverThreeDays }]">{{ configData?.arrivalPrice || configData?.localPrice }}</span>
          <span
          v-if="configData?.localPrice"
          :class="['renew-dialognew__btn_old', { 'renew-dialognew__old_over': isOverThreeDays }]"
          >{{ configData?.localPrice }}</span>
        </span>
        </div>
      </div>
    </s-dialog>
  </div>
</template>
<script>
import { CountDown } from '@shein/common-function'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'

import { RENEW_IMG_V2 } from '../../utils/index'
const { host = ''  } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}
export default {
  name: 'RenewDialog',
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    // firstPartInfo.info.personalCenterEntranceInfo.renew_pop_up_info
    configData: {
      type: Object,
      default: () => ({}),
    },
  },
  data(){
    return {
      isExpired: false,
      isOverThreeDays: false,
      renewGuideText: '',
      host,
      RENEW_IMG_V2,
      title: '',
      isShowDialog: false,
      timer: null,
    }
  },
  computed: {
    currentDialogShow(){
      return this.isShowDialog
    },
  },
  watch: {
    isShow: {
      handler(val) {
        if(val) {
          this.isShowDialog = val
        }
      },
      immediate: true,
    },
    currentDialogShow: {
      handler(val) {
        if(val) {
          // 存储当前时间
          this.recordPopUpTime()
          // 开启定时器
          this.countdown()
          // 弹出曝光埋点
          const { prime_promotion, prime_tp, total_end_time, roi, scene, popuptime } = this.configData?.buries?.[0]?.defaultParams || {}
          daEventCenter.triggerNotice({
            daId: '1-22-8-34',
            extraData: {
              prime_promotion,
              roi,
              prime_tp,
              total_end_time,
              popuptime,
              scene,
            }
          })
        } 
      },
      immediate: true,
    },
  },
  mounted(){
    this.handleTextStyle()
  },
  methods: {
    // 记录弹窗时间，以及清除缓存
    recordPopUpTime() {
      if(typeof window === 'undefined') return

      const { SiteUID } = gbRawData || {}
      const memberId = UserInfoManager.get({ key: 'memberId', actionType: 'PayBackDialog' })
      const cacheKey = (SiteUID || '') + '-' + (memberId || '') + '-'
      // 临期key
      const nearExpiredKey = cacheKey + 'primeV2RenewDialog'
      // 逾期key
      const ExpiredKey = cacheKey + 'primeV2ExpiredRenewDialog'
      const currentTime = new Date().getTime()

      if(this.configData.totalEndTime < 0) {
        localStorage.setItem(ExpiredKey, currentTime)
        localStorage.removeItem(nearExpiredKey)
      } else {
        localStorage.setItem(nearExpiredKey, currentTime)
      }
    },
    handleTextStyle() {
      requestAnimationFrame(() => {
        this.setFontStyle('renew-notice-text', 'renew-num-text', '28px', '28px')
        this.setFontStyle('renew-save-text', 'renew-save-currency', '20px', '30px')
      })
    },
    setFontStyle(id, id1, target, target1) {
      const saveTextNode = document.getElementById(id)
      const saveTextNode1 = document.getElementById(id1)
      if (!saveTextNode1) return
      if (saveTextNode1.clientWidth < saveTextNode1.scrollWidth) {
        saveTextNode1.style.fontSize = target1
        saveTextNode.style.fontSize = target
      }
    },
    countdown () {
      const self = this
      const { totalEndTime, renewGuideExpireText, renewGuideText, discountEndTime } = self.configData || {}
      const couponClass = 'coupon-color'
      // const days = Math.floor(totalEndTime / 86400)
      // this.expireDays = Math.floor(totalEndTime / 86400)
      this.isExpired = totalEndTime < 0
      this.isOverThreeDays = totalEndTime > 3 * 24 * 3600
      this.renewGuideText = renewGuideText
      // countdown
      if(discountEndTime) {
        this.renewGuideText = renewGuideExpireText
        this.timer?.clear()
        this.timer = new CountDown({
          seconds: discountEndTime,
          countFunc(time) {
            // 小于等于3天展示时分秒
            const h = `<span class="num ${couponClass}">${time.H}</span>`
            const m = `<span class="num ${couponClass}">${time.M}</span>`
            const s = `<span class="num ${couponClass}">${time.S}</span>`
            const countdownStr = `<span class="num-group">${h}:${m}:${s}</span>`

            self.title = `<span class="countdown">${countdownStr}</span>`
          },
        })
        this.timer.start()
      }
    },
    goToPrimeEvt () {
      this.isShowDialog = false
      this.closeEventCenterTrigger(1)
      location.href = `${host}${'/user/prime'}${this.isExpired ? '' : '?renew=1'}`
    },
    closeEventCenterTrigger(click_type) {
      // 关闭弹窗埋点上报
      const { prime_promotion, prime_tp, total_end_time, roi, popuptime } = this.configData?.buries?.[0]?.defaultParams || {}
      // const { prime_promotion, prime_tp, total_end_time, roi, arrivalPrice } = this.configData || {}
      daEventCenter.triggerNotice({
        daId: '1-22-8-35',
        extraData: {
          prime_promotion,
          prime_tp,
          total_end_time,
          roi,
          click_type,
          popuptime,
          scene: 'me'
        }
      })
    },
    closeDialog() {
      this.isShowDialog = false
      this.closeEventCenterTrigger(0)
    },
  }
}
</script>
<style lang="less" scoped>
/* stylelint-disable declaration-no-important */
@unit: 1px;
.renew-text__ellipsis {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.renew-dialognew {
  font-family: "Arial", "Helvetica", sans-serif !important;
  // 弹窗样式重置
  background-color: rgba(0, 0, 0, 0.8);
  .S-dialog__top {
    display: none;
  }
  .S-dialog__body {
    padding: 0;
  }
  /deep/ .sui-dialog__wrapper {
    background: none;
    box-shadow: none;
  }

  &__topnotice {
    max-width: 338px;
    margin-bottom: 74 / @unit;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    line-height: normal;
  }

  &__notice_text {
    -webkit-text-fill-color: transparent;
    background-clip: text !important;
    background: linear-gradient(180deg, #FFF 30.63%, rgba(250, 206, 169, 1) 98%);
    text-shadow: 0px 0px 12px rgba(238, 68, 14, 0.2);
    -webkit-background-clip: text;
  }

  &__notice_time {
    display: inline-block;
    -webkit-text-fill-color: transparent;
    // color: rgba(238, 68, 14, 0.80);
    text-shadow: 0px 0px 12px rgba(238, 68, 14, 0.8);
    margin-left: 5px;
    // -webkit-text-fill-color: transparent;
    background-clip: text !important;
    background: linear-gradient(90deg, #FF894C 0%, #FF6310 98%);
    // -webkit-background-clip: text;
  }

  &__bd {
    width: 379.2 / @unit;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &__close {
    position: absolute;
    top: -34 / @unit;
    right: -17 / @unit;
    cursor: pointer;
  }


  &__ft {
    margin-top: 30px;
  }


  /deep/ .num {
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 26 / @unit;
    width: 26 / @unit;
    line-height: 20 / @unit;
    font-weight: 400;
    border-radius: 3 / @unit;
    padding: 0 / @unit;
    background: rgba(255, 227, 212, 0.08);
    margin-left: 4 / @unit;
    margin-right: 4 / @unit;
    font-size: 14 / @unit;
    border: 1px solid rgba(131, 112, 113, 0.6);
  }
  // /deep/ .coupon-color {
  //   color: #C73BFF;
  // }
  /deep/.countdown {
    -webkit-text-fill-color: transparent;
    background: linear-gradient(180deg, #FFF 0%, #FFFBBF 100%);
    background-clip: text !important;
    -webkit-background-clip: text;
    display: flex;
    font-size: 14 / @unit;
    font-weight: 400;
    color: #fff;
    align-items: center;
    flex-wrap: wrap;
  }
  /deep/ .num-group{
    direction: ltr /* rtl:ignore */; 
  }

  &__btn {
    display: flex;
    justify-content: center;
    align-items: baseline;
    position: relative;
    margin: 17px 10px 20px 10px;
    height: 50px;
    // display: inline-block;
    padding: 0 27px;
    overflow: hidden;
    cursor: pointer;
    min-width: 317px;

    // border-radius: 90px;
    // border: 2px solid rgba(255, 255, 255, 0.20);
    background-repeat: no-repeat;
    background-size: cover;
    background-size: 100% 100%;

    line-height: 50px;
    font-size: 16px;
    font-weight: 700;
    span {
      display: inline-block;
    }
  }

 &__btn_coupon {
    animation: heartbeat 1s infinite;
  }

    @keyframes heartbeat {
      0%, 100% {
        transform: scale(1);
      }
      50% {
        transform: scale(0.83);
      }
      100% {
        transform: scale(1);
      }
    }
  
    &__btn_text {
      max-width: 160px;
      color: #FFF;
      text-align: center;
      font-size: 16px;
      font-weight: 700;
    }

    &__btn_over {
      color: rgba(132, 60, 4, 1);
    }

    &__btn_price {
      color: #FFDC5C;
      font-size: 22px;
      font-weight: 700;
      margin: 0 7px 0 6px;
    }

    &__price_over {
      color: rgba(255, 56, 0, 1);
    }

    &__btn_old {
      color: rgba(255, 255, 255, .6);
      font-size: 13px;
      font-weight: 400;
      text-decoration: line-through;
    }

    &__old_over {
      color: rgb(89, 29, 1);
      opacity: .6;
    }

  &__promotion {
    max-width: 380px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 10px 0 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 860;
  }


  &__discount {
    -webkit-text-fill-color: transparent;
    background-clip: text !important;
    background: linear-gradient(180deg, rgba(255, 211, 141, 1) 36.79%, rgba(255, 211, 141, 1) 98.33%);
    -webkit-background-clip: text;
    margin: 0px 4px 0 4px;
    max-width: 174px;
  }

  &__discount_countdown {
    max-width: 145px;
  }

  &__discount_over {
    -webkit-text-fill-color: transparent;
    background-clip: text !important;
    background: linear-gradient(180deg, rgba(255, 137, 76, 1) 36.79%, rgba(255, 99, 16, 1) 98.33%);
    -webkit-background-clip: text;
    margin: 0px 4px 0 4px;
    max-width: 174px;
  }

  &__ico {
    display: inline-block;
    width: 12px;
    height: 15px;
    background-size: contain;
  }


  &__timetext {
    max-width: 155px;
    -webkit-text-fill-color: transparent;
    background-clip: text !important;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.80) 36.79%, rgba(250, 206, 169, 0.80) 98.33%);
    -webkit-background-clip: text;
    font-weight: 510;
  }


  &__timetext_countdown {
    max-width: 104px;
  }


}

.renew-layout::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0.9;
    background: linear-gradient(39deg, rgba(255, 205, 201, 0.40) 7.4%, rgba(255, 205, 201, 0.00) 48.41%), linear-gradient(225deg, rgba(255, 168, 165, 0.60) 4.73%, rgba(255, 218, 217, 0.00) 48.85%), linear-gradient(129deg, #FDE69A -0.37%, #FBBB71 97.18%), linear-gradient(158deg, #FFFBF4 1.31%, #FFEFDB 89.24%);
    filter: blur(10px);
    border-radius: 19px;
    z-index: -1;
  }

.renew-layout {
  position: relative;
  width: 345 / @unit;
  min-height: 200 / @unit;
  flex-shrink: 0;
  background: linear-gradient(34deg, rgba(254, 224, 196, 0.00) 69.77%, #FEE0C4 92.8%), linear-gradient(351deg, rgba(253, 222, 168, 0.00) 50.57%, rgba(252, 225, 200, 0.70) 73.81%, #FDC4A8 96.58%), linear-gradient(180deg, #FBF1E8 4.07%, #FFF 35.11%, #FBF1E8 128.23%);
  border-radius: 19px;
  border: 1.2px solid #FFFBFA;
  // box-shadow: 0 0 5px 5px rgba(255, 239, 219, .2);
  
  /deep/ .coupon-price{
    font-size: 18 / @unit; 
  }
  &__hd {
    position: absolute;
    width: 135 / @unit;
    height: 108 / @unit;
    left: 105 / @unit;
    top: -73 / @unit;
    background-size: cover;
  }

  &__light {
    z-index: -1;
    position: absolute;
    width: 337 / @unit;
    height: 144 / @unit;
    left: 14 / @unit;
    top: -116 / @unit;
    background-size: cover;
  }

  &__text {
    font-size: 22px;
    font-weight: 700;
    margin: 32px 16px 12px 16px;
    color:  #FF5322;
    text-align: center;
  }

  &__text_subtitle {
    display: inline-block;
    max-width: 310px;
  }

  &__text_wrapper {
    display: flex;
    align-items: baseline;
    justify-content: center;
    span {
      display: inline-block;
    }
  }

  &__text_spec {
    max-width: 310px;
    background-clip: text !important;
    // text-shadow: 0.6px 0.6px 0px rgba(255, 252, 244, 0.72);
    font-family: Roboto;
    font-size: 21px;
    font-weight: 600;
    line-height: normal;

    background: linear-gradient(275deg, #873C00 2.71%, #CA6235 96.39%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &__text_notice {
    color: rgba(255, 83, 34, 1);
    text-shadow: 0.6px 0.6px 0px rgba(255, 252, 244, 0.72);
    // background-clip: text !important;
    text-align: center;
    // text-shadow: 0.96px 0.96px 0px rgba(255, 252, 244, 0.72);
    font-size: 40px;
    font-weight: 700;
    line-height: normal;
  }
  &__text_num {
    max-width: 160px;
    color: rgba(255, 83, 34, 1);
    text-shadow: 0.6px 0.6px 0px rgba(255, 252, 244, 0.72);
    // background-clip: text !important;
    // background: linear-gradient(90deg, #873D01 0%, #6A371E 100%);
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
    font-size: 40px;
    font-weight: 860;
    line-height: normal;
  }
  
  &__hd_coupon {
    background: linear-gradient(99deg, #F7516A -5.17%, #9124FF 95.19%); 
  }
  &__bd {
    flex-shrink: 0;
    position: relative;
    margin-top: -186 / @unit;
    border-bottom-left-radius: 10 / @unit;
    border-bottom-right-radius: 10 / @unit;
  }
}


// 内容区域
.renew-mainnew {
  position: relative;

  &__triangle {
    width: 18px;
    height: 8px;
    position: absolute;
    top: -7px;
    left: 140px;
  }

  &__background {
    direction: ltr/* rtl: ltr */;
    position: absolute;
    background-repeat: no-repeat;
    background-size: cover;
    width: 127px;
    height: 108px;
  }

  &__title {
    max-width: 266px;
    color: #FFDEBE;
    font-size: 13px;
    font-weight: 590;
  }

  &__save {
    display: flex;
    justify-content: center;
    align-items: baseline;
    max-width: 276px;
    -webkit-text-fill-color: transparent;
    background-clip: text !important;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    background: linear-gradient(90deg, rgba(255, 217, 161, 1) 0%, rgba(255, 195, 93, 1) 100%);
    -webkit-background-clip: text;
    margin: 3px 0 5px 0;

    span {
      display: inline-block;
    }
  }

  &__save_text {
    max-width: 276px;
    margin-right: 6px;
  }

  &__save_currency {
    direction: ltr/* rtl: ltr */;
    max-width: 160px;
    -webkit-text-fill-color: transparent;
    background-clip: text !important;

    background: linear-gradient(90deg, rgba(255, 217, 161) 0%, rgba(255, 195, 93) 100%);
    -webkit-background-clip: text;
    font-size: 40px;
    font-weight: 860;
    line-height: 40px;
  }


  &__money {
    -webkit-text-fill-color: transparent;
    background-clip: text !important;
    background: linear-gradient(90deg, #FFF2DD 0%, #FBBB84 100%);
    -webkit-background-clip: text;
    font-size: 40px;
    font-weight: 860;
  }

  &__roi {
    display: flex;
    justify-content: center;
    align-items: baseline;
    max-width: 276px;
    color: #FFDEBE;
    font-size: 13px;
    font-weight: 400;
    opacity: 0.55;
    span {
      display: inline-block;
    }
  }
  &__roi_num {
    margin-right: 3px;
    font-weight: 860;
  }

  &__roi_text {
    max-width: 240px;
    font-weight: 860;
  }

  &__divider {
    height: 1px;
    // background: radial-gradient(43.51% 10.17% at 50.37% -4.49%, #D5894C 0%, rgba(82, 55, 42, 0.00) 100%), linear-gradient(121deg, #5A3E31 11.42%, #331C11 84.72%);
    width: 100%;
    background-size: 100% 100%;
    // border: none;
    // outline: 0.6px dashed rgba(255, 186, 151, 0.5);
    // border-top: 0.6px solid dashed rgba(255, 186, 151, 0.5);
    margin: 20px 0 16px 0;
  }

  &__items {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
  }

  &__item {
    width: 26px;
    height: 22px;
    margin-right: 24px;
  }

  &__item_last {
    margin-right: 0;
  }


  &__item_ico {
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-right: 6px;
  }

  &__item_desc {
    max-width: 130px;
    color: #FFBA97;
    font-size: 12px;
    font-weight: 510;
  }

  &__item_save {
    max-width: 91px;
    color: #FFDEBE;
    font-size: 12px;
    font-weight: 700;
  }

  &__item_detail {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__item_wrapper {
    display: flex
  }

  &__items_col {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    span {
      display: inline-block;
    }
  }

  &__item_col {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 38px;

    color: #FFBA97;
    font-size: 12px;
    font-weight: 510;
    padding: 0 20px;
    // border-bottom: 1px dashed #856B57;
  }

  &__item_dash {
    position: absolute;
    height: 1px;
    width: 100%;
    background-size: 100% 100%;
  }


  &__item_col:nth-child(even) {
    background: rgba(255, 255, 255, 0.03);
  }
  
  &__bt {
    margin: 14px 24px 19px 4px;
    color: #FFBA97;
    text-align: center;
    font-size: 10px;
    font-weight: 510;
    line-height: normal;
    opacity: 0.55;

  }

  &__hd {
    padding-bottom: 33 / @unit;
    text-align: left;
  }
  &__hd-content {
    color: rgba(#873C00, 0.8);
    font-size: 14 / @unit;
    font-weight: 400;
    line-height: 24 / @unit;
  }
  &__bd {
    margin: 0 22px 22px 22px;
    background: radial-gradient(43.51% 10.17% at 50.37% -4.49%, rgba(90, 62, 49, 1) 0%, rgba(51, 28, 17, 1) 100%), linear-gradient(121deg, rgba(90, 62, 49, 1) 11.42%, rgba(51, 28, 17, 1) 84.72%);
    // border: 2.4px solid rgba(255, 255, 255, 0.50);
    border-radius: 14px;
    padding: 20px;
    position: relative;
  }
  &__bd_close {
    padding: 10px 0 1px 0;
  }

  &__bd::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 14px;
    background: none;
    width: 100%;
    height: 100%;
    
    box-shadow: 0 0 0 2.4px rgba(255, 255, 255, 0.5), 0 0 0 2.4px #FAE8D4;
    z-index: -1; 
  }
  
  &__bd_coupon {
    color: #F8526D;
  }
  
}
</style>
