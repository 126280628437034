// https://wiki.dotfashion.cn/pages/viewpage.action?pageId=1398005756
import { LEVEL1, LEVEL2, LEVEL3 } from './config'
const { SiteUID: siteUid, NEW_BFF_VERSION_SWTICH, SSR_BFF_SWITCH  } = gbCommonInfo

const levelMapping = {
  'LEVEL1': LEVEL1,
  'LEVEL2': LEVEL2,
  'LEVEL3': LEVEL3,
}

const isFeatureEnabled = (features, apolloConfig = NEW_BFF_VERSION_SWTICH) =>{

  if (!apolloConfig) {
    return features.reduce((result, feature) => {
      result[feature] = false
      return result
    }, {})
  }

  return features.reduce((result, feature) => {
    const allowedSites = apolloConfig[feature]
    if (!allowedSites) {
      result[feature] = false
    } else if (allowedSites.length === 1) {
      const allowedSite = allowedSites[0]
      result[feature] = allowedSite === 'OPENALL' || allowedSite === siteUid || (levelMapping[allowedSite]?.includes(siteUid) || false)
    } else {
      result[feature] = allowedSites.includes(siteUid)
    }
    return result
  }, {})
}

/**
 * @description 是否开启bff接口
 * @param {Array} features  ['pagename_v1', 'pagename_v2'], v1指的是第一批放量的接口
 * @returns {Object}  result { giftcard_v1: true, giftcard_v2: false }
 */
export function isSwitchBffApiVersion(features) {
  return isFeatureEnabled(features)
}

export function isSwitchBffSsr(features) {
  return isFeatureEnabled(features, SSR_BFF_SWITCH)
}
