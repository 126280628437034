<template>
  <UserCenterContainer
    :context="context"
  />
</template>

<script>
import { defineComponent } from 'vue'
import UserCenterContainer from './index-new.vue'

export default defineComponent({
  name: 'UserCenter',
  components: {
    UserCenterContainer,
  },
  props: {
    context: {
      type: Object,
      default: () => ({}),
    },
  },
})
</script>
