<template>
  <div>
    <s-dialog
      :visible="currentDialogShow"
      class="renew-dialog"
      append-to-body
    >
      <div class="renew-dialog__bd">
        <div class="renew-layout">
          <div :class="`renew-layout__hd ${configData.isCoupons ? 'renew-layout__hd_coupon' : ''}`">
            <div
              class="renew-layout__hd_txt"
              v-html="title"
            ></div>
          </div>

          <div class="renew-layout__bd">
            <div class="renew-gift">
              <div 
                class="renew-gift__2"
                :style="{ backgroundImage: `url(${RENEW_IMG?.giftImage})` }"
              ></div>
            </div>
            <div 
              class="renew-main"
              :style="{ backgroundImage: `url(${RENEW_IMG?.bgTopImage})` }"
            >
              <div class="renew-main__hd">
                <div 
                  class="renew-main__hd-content"
                  v-html="configData?.save_money_content"
                >
                </div>
                <div 
                  class="renew-main__hd-content" 
                  v-html="configData?.roi_content"
                >
                </div>
              </div>
              <div 
                :class="`renew-main__bd ${configData.isCoupons ? 'renew-main__bd_coupon' : ''}`"
                :style="{ backgroundImage: `url(${ configData.isCoupons ? RENEW_IMG?.renewMainBgCoupon : RENEW_IMG.renewMainBg})` }"
              >
                <div 
                  :class="configData.isCoupons ? 'renew-main__bd-text' : getRenewMainBdClass(configData?.renew_content_line1)"
                  v-html="configData?.renew_content_line1"
                >
                </div>
                <div 
                  :class="configData.isCoupons ? getRenewMainBdClass(configData?.renew_content_line2) : 'renew-main__bd-text'"
                  v-html="configData?.renew_content_line2"
                >
                </div>
              </div>
              <div
                :class="`renew-main__ft ${configData.isCoupons ? 'renew-main__ft_coupon' : ''}`"
                @click="goToPrimeEvt"
              >
                <div
                  v-if="configData.isCoupons" 
                  class="renew-main__bubble"
                ></div>
                <div v-html="configData?.button_content"></div>
              </div>
            </div>
          </div>

          <div class="renew-layout__ft">
            <!-- 关闭按钮 -->
            <i 
              class="renew-dialog__close"
              :style="{ backgroundImage: `url(${RENEW_IMG?.closeImage})` }"
              @click="closeDialog"
            >
            </i>
          </div>
        </div>
      </div>
    </s-dialog>
  </div>
</template>
<script>
import { CountDown, template } from '@shein/common-function'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'

import { RENEW_IMG } from '../../utils/index'
const { host = ''  } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}
export default {
  name: 'RenewDialog',
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    // firstPartInfo.info.personalCenterEntranceInfo.renew_pop_up_info
    configData: {
      type: Object,
      default: () => ({}),
    },
  },
  data(){
    return {
      host,
      RENEW_IMG,
      title: '',
      isShowDialog: false,
      timer: null,
    }
  },
  computed: {
    currentDialogShow(){
      return this.isShowDialog
    },
  },
  watch: {
    isShow: {
      handler(val) {
        if(val) {
          this.isShowDialog = val
        }
      },
      immediate: true,
    },
    currentDialogShow: {
      handler(val) {
        if(val) {
          // 存储当前时间
          this.recordPopUpTime()
          // 开启定时器
          this.countdown()
          // 弹出曝光埋点
          
          const { prime_promotion, prime_tp, total_end_time, roi, scene, popuptime } = this.getBuries(1) || {}
          // const { isExpired } = this.configData
          daEventCenter.triggerNotice({
            daId: '1-22-8-34',
            extraData: {
              prime_promotion,
              roi,
              prime_tp,
              total_end_time,
              popuptime,
              scene,
            }
          })
        } 
      },
      immediate: true,
    },
  },
  mounted(){
  },
  methods: {
    getBuries(type) {
      const obj = this.configData?.buries?.find(item => item.buriedType == type) || {}
      return obj?.defaultParams
    },
    // 记录弹窗时间，以及清除缓存
    recordPopUpTime() {
      if(typeof window === 'undefined') return

      const { SiteUID } = gbRawData || {}
      const memberId = UserInfoManager.get({ key: 'memberId', actionType: 'PayBackDialog' })
      const cacheKey = (SiteUID || '') + '-' + (memberId || '') + '-'
      // 临期key
      const nearExpiredKey = cacheKey + 'primeV2RenewDialog'
      // 逾期key
      const ExpiredKey = cacheKey + 'primeV2ExpiredRenewDialog'
      const currentTime = new Date().getTime()

      if(this.configData.isExpired) {
        localStorage.setItem(ExpiredKey, currentTime)
        localStorage.removeItem(nearExpiredKey)
      } else {
        localStorage.setItem(nearExpiredKey, currentTime)
      }
    },
    countdown () {
      const self = this
      const { seconds, title_line1, isExpired, title_line2 } = self.configData
      const couponClass = 'coupon-color'
      if(isExpired) {
        const days = Math.ceil(seconds / 86400)
        const d = `<i class="num ${couponClass}">${days && Math.abs(days)}</i>`
        self.title = `${template(d, title_line1)}`
      } else {
        this.timer?.clear()
        this.timer = new CountDown({
          seconds: seconds - 1,
          dayMode: true,
          countFunc(time) {
            const days = parseInt(time.D)
            if(days >= 3) {
              // 大于3天展示xx天
              const d = `<i class="num ${couponClass}">${days}</i>`
              self.title = title_line1 + ` ${template(d, title_line2)}`
            } else {
              // 小于等于3天展示时分秒
              const d = `<i class="num ${couponClass}">${days}</i>`
              const h = `<span class="num ${couponClass}">${time.H}</span>`
              const m = `<span class="num ${couponClass}">${time.M}</span>`
              const s = `<span class="num ${couponClass}">${time.S}</span>`
              const countdownStr = `<span class="num-group">${h}:${m}:${s}</span>`

              self.title = title_line1 + `<br><div class="countdown">${template(d, title_line2) + countdownStr }</div>`
            }
          },
        })
        this.timer.start()
      }
    },
    goToPrimeEvt () {
      this.isShowDialog = false
      this.closeEventCenterTrigger(1)
      location.href = `${host}${this.configData.button_jump_url}`
    },
    closeEventCenterTrigger(click_type) {
      // 关闭弹窗埋点上报
      const { prime_promotion, prime_tp, total_end_time, roi, popuptime } = this.getBuries(2) || {}
      daEventCenter.triggerNotice({
        daId: '1-22-8-35',
        extraData: {
          prime_promotion,
          prime_tp,
          total_end_time,
          roi,
          click_type,
          popuptime,
          scene: 'me'
        }
      })
    },
    getRenewMainBdClass(text) {
      if(text?.length > 18) {
        return 'renew-main__bd-text-bold'
      } else {
        return 'renew-main__bd-text-big'
      }
    },
    closeDialog() {
      this.isShowDialog = false
      this.closeEventCenterTrigger(0)
    },
  }
}
</script>
<style lang="less" scoped>
@unit: 1px;
.renew-dialog {
  // 弹窗样式重置
  background-color: rgba(0, 0, 0, 0.5);
  .S-dialog__top {
    display: none;
  }
  .S-dialog__body {
    padding: 0;
  }
  /deep/ .sui-dialog__wrapper {
    background: none;
    box-shadow: none;
  }
  &__hd {
    position: absolute;
  }
  &__close{
    position: absolute;
    bottom: -40 / @unit;
    left: 50%;
    right: 50%;
    margin-left: -10 / @unit;
    margin-right: -10 / @unit;
    width: 20 / @unit;
    height: 20 / @unit;
    background-size: cover;
    cursor: pointer;
  }
}
.renew-layout {
  width: 450 / @unit;
  /deep/ .num {
    text-align: center;
    display: inline-block;
    height: 20 / @unit;
    width: 20 / @unit;
    color: #C1724A;
    line-height: 20 / @unit;
    font-weight: 600;
    border-radius: 4 / @unit;
    background: #FFF;
    margin-left: 4 / @unit;
    margin-right: 4 / @unit;
    font-size: 12 / @unit;
    font-style: normal;
  }
  /deep/ .coupon-color {
    color: #C73BFF;
  }
  /deep/.countdown {
    display: flex;
    font-size: 14 / @unit;
    font-weight: 600;
    align-items: center;
    flex-wrap: wrap;
  }
  /deep/ .num-group{
    direction: ltr /* rtl:ignore */; 
  }
  /deep/ .coupon-price{
    font-size: 18 / @unit; 
  }
  // 标题：倒计时区域
  &__hd {
    text-align: left;
    position: relative;
    width: 414 / @unit;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10 / @unit;
    background: linear-gradient(148deg, #DF7742 9.78%, #DB5B19 105.85%);
    padding-top: 15 / @unit;
    padding-left: 20 / @unit;
    padding-right: 20 / @unit;
    padding-bottom: 52 / @unit;
    color: #FFF;
    font-size: 16 / @unit;
    font-weight: 700;
    line-height: 24 / @unit;
  }
  &__hd_txt {
    width: 340 / @unit;
  }
  &__hd_coupon {
    background: linear-gradient(99deg, #F7516A -5.17%, #9124FF 95.19%); 
  }
  &__bd {
    position: relative;
    margin-top: -45 / @unit;
    border-bottom-left-radius: 10 / @unit;
    border-bottom-right-radius: 10 / @unit;
  }
}
// 礼物区域
.renew-gift {
  position: absolute;
  right: 5 / @unit;
  top: 10 / @unit;
  &__2 {
    position: absolute;
    top: -96 / @unit;
    right: -40 / @unit;
    width: 174.47 / @unit;
    height: 187.912 / @unit;
    background-size: cover;
  }
  &__3 {
    position: absolute;
    top: -64 / @unit;
    left: 30 / @unit;
    width: 144 / @unit;
    height: 142 / @unit;
    transform: rotate(15.893deg);
    background-size: cover;
  }
}

// 内容区域
.renew-main {
  position: relative;
  background-size: 100% auto;
  background-repeat: no-repeat;
  padding-top: 14 / @unit;
  padding-left: 24 / @unit;
  padding-right: 24 / @unit;
  padding-bottom: 32 / @unit;
  box-sizing: border-box;
  z-index: @zindex-hack;
  &::before{
    position: absolute;
    top: 85 / @unit;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: @zindex-hack-negative;
    content: "";
    background: #FFEED9;
    border-bottom-left-radius: 10 / @unit;
    border-bottom-right-radius: 10 / @unit;
  }
  &__hd {
    padding-bottom: 33 / @unit;
    text-align: left;
  }
  &__hd-content {
    color: rgba(#873C00, 0.8);
    font-size: 14 / @unit;
    font-weight: 400;
    line-height: 24 / @unit;
  }
  &__bd {
    position: relative;
    display: flex;
    width: 364 / @unit;
    height: 106 / @unit;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    margin: auto;
    padding: 16 / @unit 0;
    background-size: 100% auto;
    background-repeat: no-repeat;
    color: #873C00;
    font-weight: 860;
    line-height: 30 / @unit;
    
  }
  &__bd-text {
    font-size: 20 / @unit;
  }
  &__bd-text-bold {
    font-size: 22 / @unit;
  }
  &__bd-text-big {
    font-size: 26 / @unit;
  }
  &__bd_coupon {
    color: #F8526D;
  }
  &__ft {
    cursor: pointer;
    height: 48 / @unit;
    width: 335 / @unit;
    margin: 40 / @unit auto 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #873C00;
    font-size: 14 / @unit;
    font-weight: 700;
    border-radius: 34 / @unit;
    background: linear-gradient(108deg, #FFC3A5 12.63%, #EAA27E 46.69%);
    box-shadow: 1px 1px 1px 0px rgba(255, 255, 255, 0.34) inset, 0px 6px 10px 0px rgba(255, 255, 255, 0.52) inset, -4px -3px 9px 0px #DE9B79 inset;
  }
  &__ft_coupon {
    height: 48 / @unit;
    color: #FFF;
    font-size: 14 / @unit;
    font-weight: 800;
    border-radius: 50 / @unit;
    border: 2px solid #FFE2D3;
    background: linear-gradient(98deg, #FF5656 1.43%, #C73BFF 100%);
    box-shadow: 0px -5.5px 13.2px 0px rgba(231, 49, 99, 0.90) inset, 0px 4.4px 6.6px 0px rgba(117, 58, 23, 0.32);
  }
  &__bubble {
    position: absolute;
    top: 3 / @unit;
    width: 239 / @unit;
    height: 23 / @unit;
    flex-shrink: 0;
    border-radius: 24 / @unit;
    opacity: 0.4;
    background: linear-gradient(180deg, #FFF 0.2%, rgba(255, 255, 255, 0.00) 99.8%);
  }
}
</style>
